.top_right_btns i[data-v-188c76d7] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.item_icon[data-v-188c76d7] {
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}
.item_icon i[data-v-188c76d7] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-188c76d7] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-188c76d7] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-188c76d7] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-188c76d7] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-188c76d7] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-188c76d7] {
  color: #e6a23c;
}
.el-select[data-v-188c76d7] {
  margin-left: 0px;
}
.small_cell div[data-v-188c76d7] {
  padding-left: 2px !important;
  padding-right: 0 !important;
}
.icon-gantanhao1[data-v-188c76d7] {
  font-size: 13px;
  margin-right: 3px;
}