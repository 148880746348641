.content[data-v-1d066794] {
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content span[data-v-1d066794] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-1d066794] {
  padding: 0;
  margin: 0;
}
.content td[data-v-1d066794] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.content .center[data-v-1d066794] {
  text-align: center;
}
.content .weight[data-v-1d066794] {
  font-weight: 600;
  background-color: #d3eff5;
}