.item_icon[data-v-467830b6] {
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}
.item_icon i[data-v-467830b6] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.el-select[data-v-467830b6] {
  margin-left: 0px;
}